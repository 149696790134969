import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useQuery } from '../helper/Tools';

import _ from 'lodash';
import { formatDate } from '../helper/Tools';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles'
import { Typography, Link, Grid, Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Checkbox, FormControlLabel, InputAdornment, Tooltip, Icon, Toolbar, FormControl, Input, IconButton, TextField, MenuItem } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { getUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import FilterListIcon from '@material-ui/icons/FilterList';
import RestoreIcon from '@material-ui/icons/Restore';

function TableToolbar(props) {
    const classes = useStyles();
    const { t  } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}

export default function PointPurchases() {
    // const classes = useStyles();
    const theme = useTheme();
    const query = useQuery();
    const searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [tableData, setTableData] = useState([]);
    const { permissions } = useSelector(state => state.general);
    const [statusType, setStatusType] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const statusTypeToCode = { 'approved': 1, 'cancelled': 2 };
    const [updateDialog, setOpenUpdateDialog] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [filters, setFilter] = useState({'source': "all"});
    const [dateFilter, setDateFilter] = useState({ sdate:'', edate:''});
    const [reset, setReset]=useState(false);

    const history = useHistory();
    const rowsPerPageOptiosArray = [10, 20, 30];
    const url_pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const url_perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 20;
    const url_searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const url_orderBy = query.get("orderBy") && query.get("orderBy") !== '' ? query.get("orderBy") : '';
    const url_order = query.get("order") && query.get("order") !== '' ? query.get("order") : 'asc';
    const [page, setPage] = useState(url_pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(url_perPage);
    const [querySearch, setQueryValue] = useState(url_searchKeyword);
    const [orderBy, setOrderBy] = useState(url_orderBy);
    const [order, setOrder] = useState(url_order);
    const [totalCount, setTotalCount] = useState(0);
    const [source, setSource] = useState([]);
    const [totalPoint, setTotalPoint] = useState([]);
    var currDate = new Date();
    // var currDate = (new Date().getFullYear()).toString() + '-' + (new Date().getMonth()+1).toString() + '-' + (new Date().getDate()).toString();

    const setUrl = (paging, rpp, sv, column, orderSort) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        if(column){
            params['orderBy'] = column;
            params['order'] = orderSort;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/point-purchase${searchString !== '' ? '?'+searchString : ''}`);
    }

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch, 'order_by': orderBy, 'order': order };
        getUrl(`admin/pointPurchase/listing`, params).then(response => {
            setTableLoading(false);
            if (response.status === 1 && isMountedRef.current) {
                setTableData(response.data.data);
                setTotalCount(response.data.total);
                setTotalPoint(response.total);
                setSource(response.source);
            }
        }).catch((error) => {
            setTableLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        })
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, page, rowsPerPage, querySearch, orderBy, order]);

    const alertChangeStatus = (event, data, type) => {
        setStatusType(type);
        setSelectedRow(data);
        setOpenUpdateDialog(true);
    };

    const updateStatus = () => {
        setLoading(true);

        let apiData = { status: statusTypeToCode[statusType], ids: _.map(selectedRow, 'id') };
        putUrl(`admin/pointPurchase/status`, apiData)
            .then((response) => {
                setLoading(false);
                let { status, message, error } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    let newTableData = [...tableData];
                    _.map(selectedRow, (row, index) => {
                        let dataIndex = tableData.findIndex(item => item.id === row.id);
                        newTableData[dataIndex] = { ...row, status: statusTypeToCode[statusType] };
                    })
                    setTableData(newTableData);
                } else {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n " + value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            });
        setOpenUpdateDialog(false);
    };

    useEffect(() => {
        setPage(1);
        setUrl(1, rowsPerPage, querySearch, orderBy, order);
    }, []);

    const handlePageChange = (page) => {
        setPage(page+1);
        setUrl(page+1, rowsPerPage, querySearch, orderBy, order);
    }
    const handleRowsPerPageChange = (pageSize) => {
        setRowsPerPage(pageSize);
        setPage(1);
        setUrl(1, pageSize, querySearch, orderBy, order);
    }
    const handleOrderChange = (orderedColumnId, orderDirection) => {
        let tempOrderColumn = '';
        let tempOrder = 'asc';
        switch(orderedColumnId){
            case 0: tempOrderColumn = 'id'; break;
            case 1: tempOrderColumn = 'source'; break;
            case 2: tempOrderColumn = 'username'; break;
            case 3: tempOrderColumn = 'name'; break;
            case 4: tempOrderColumn = 'created_at'; break;
            case 5: tempOrderColumn = 'updated_at'; break;
            case 6: tempOrderColumn = 'status'; break;
            default: tempOrderColumn = '';
        }
        if(tempOrderColumn === orderBy){
            tempOrder = order === "asc" ? "desc" : "asc";
        }else{
            tempOrder = orderDirection;
        }
        setOrderBy(tempOrderColumn);
        setOrder(tempOrder);
        setPage(1);
        setUrl(1, rowsPerPage, querySearch, tempOrderColumn, tempOrder);
    }
    const handleSearchChange = (value) => {
        setQueryValue(value);
        setPage(1);
        setUrl(1, rowsPerPage, value, orderBy, order);
    }
    function handleSubmit(event) {
        event.preventDefault();        
        setUrl( searchValue);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: searchKeyword});

    const handleChangeFilters = event => {
        const { name, value } = event.target;
        setFilter({ ...filters, [name]: value });
        setPage(1);
        setUrl(1, rowsPerPage, searchValue);
    }

    const handleDateChange = event => {
        const { name, value } = event.target;
        setDateFilter({ ...dateFilter, [name]: value });
        setPage(1);
        setUrl(1, rowsPerPage, searchValue);
    }

    const handleReset = ()=>{
        setDateFilter({...dateFilter, sdate: '', edate: ''});
        setFilter({ ...filters, 'source': "all" });
        setReset(true);
        setPage(1);
        setUrl(1, rowsPerPage, searchValue);
    }

    const handleFilter = () => {
        isMountedRef.current = true;
        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch, 'user':filters.user, 'source': filters.source == "all" ? "" : filters.source, 'sdate': dateFilter.sdate ? dateFilter.sdate : '', 'edate': dateFilter.edate ? dateFilter.edate : ''};
        setTableLoading(true);

        getUrl('admin/pointPurchase/listing', params).then(result => {
            if(isMountedRef.current) {
                setTableLoading(false);
                if(result.status === 1){
                    setTableData(result.data.data);
                    setTotalCount(result.data.total);
                    setTotalPoint(result.total);
                    setSource(result.source);
                    setReset(false);
                    if(rowsPerPageOptiosArray.indexOf(parseInt(rowsPerPage)) == -1){
                        setRowsPerPage(result.data.total);
                        setUrl(1, parseInt(result.data.total, 10), searchValue);
                    }
                }else{
                    addAlert(result.message);
                    setTotalCount();
                    setReset(false);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setTableLoading(false);
                setTotalCount(0);
                setReset(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
        
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('title.pointPurchase')}</Typography>
                    {_.includes(permissions, "point_purchase_add") ?
                        <Link underline='none' color="inherit" to="/point-purchase/add" component={RouterLink}>
                            <Button size="medium" variant="outlined" startIcon={<AddIcon />}>{t('button.new')}</Button>
                        </Link>
                        : null
                    }
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="search-user"
                    label={t('User')}
                    value={filters.user ? filters.user : ''}
                    name="user"
                    onChange={handleChangeFilters}
                    InputLabelProps={{ shrink: true }}
                    style={{ padding: 5 }}
                />
                <TextField
                    id="datetime-local"
                    label={t('reports.dailysales.from')}
                    type="date"
                    value={dateFilter.sdate ? dateFilter.sdate : ''}
                    name="sdate"
                    onChange={handleDateChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ max: currDate }}
                    style={{ padding: 5 }}
                />
                <TextField
                    id="datetime-local"
                    label={t('reports.dailysales.to')}
                    type="date"
                    value={dateFilter.edate ? dateFilter.edate : ''}
                    name="edate"
                    onChange={handleDateChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ max: currDate }}
                    style={{ padding: 5 }}
                />
                <TextField
                    id="select-source"
                    label={t('point.source')}
                    select
                    value={filters.source}
                    name="source"
                    onChange={handleChangeFilters}
                    InputLabelProps={{ shrink: true }}
                    style={{ padding: 5 }}
                >
                    <MenuItem value="all">{t('All')}</MenuItem>
                    {
                        _.map(source, (option) => {
                            return <MenuItem key={option} value={option}>{`${option}`}</MenuItem>
                        })
                    }
                </TextField>
                <Grid item>
                    <Button size="medium" variant="outlined" color="inherit" onClick={() => handleReset()} startIcon={<RestoreIcon />}>{t('button.reset')}</Button>
                    <Button size="medium" variant="contained" color="primary" onClick={() => handleFilter()} startIcon={<FilterListIcon />} style={{marginLeft:10}}>{t('button.filter')}</Button>
                </Grid>
                {/* {searchToolbar} */}
            </Grid>
            <Grid item xs={12}>
                Total Pending Point: {totalPoint[0] ?? 0}<br/>
                Total Approved Point: {totalPoint[1] ?? 0}<br/>
                Total Cancelled Point: {totalPoint[2] ?? 0}<br/>
                {
                tableLoading
                ?
                <LinearProgress />
                :
                <MaterialTable
                    title=""
                    columns={[
                        { title: 'ID', field: 'id' },
                        { title: t('point.source'), field: 'source' },
                        { title: t('profile.username'), field: 'username', render: rowData => <Typography>{rowData.user.username}</Typography> },
                        { title: t('profile.name'), field: 'name', render: rowData => <Typography>{rowData.user.name}</Typography> },
                        { title: t('point.points'), field: 'point' },
                        { title: t('table.created_at'), field: 'created_at',render:rowData=><p>{formatDate(rowData.created_at)}</p> },
                        { title: t('table.updated_at'), field: 'created_at',render:rowData=><p>{formatDate(rowData.updated_at)}</p> },
                        { title: t('table.status'), field: 'status', render: rowData => t('pointPurchase.status.' + rowData.status) }
                    ]}
                    data={tableData}
                    options={{
                        pageSize: rowsPerPage,
                        selection: _.includes(permissions, "point_purchase_edit") ? true : false,
                        pageSizeOptions: rowsPerPageOptiosArray,
                        debounceInterval: 1000,
                        searchText: querySearch,
                        toolbar: true,
                        search: false
                    }}
                    page={page-1}
                    totalCount={totalCount}
                    actions={_.includes(permissions, "point_purchase_edit") ? [
                        {
                            tooltip: t('Approve'),
                            icon: () => <RotateLeftIcon />,
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'approved')
                        },
                        {
                            tooltip: t('Cancel'),
                            icon: 'block',
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'cancelled')
                        },
                    ] : null}
                    style={{ ...theme.box1, ...theme.p20 }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                            nRowsSelected: t('table.rowsSelected')
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                    onChangePage = {(page) => handlePageChange(page)}
                    onChangeRowsPerPage = {(pageSize) => handleRowsPerPageChange(pageSize)}
                    onOrderChange = {(orderedColumnId, orderDirection) => handleOrderChange(orderedColumnId, orderDirection)}
                    onSearchChange = {(value) => handleSearchChange(value)}
                />
                }
            </Grid>
            <Dialog
                open={updateDialog}
                onClose={() => setOpenUpdateDialog(false)}
                aria-labelledby="update-status-dialog-title"
                aria-describedby="update-status-dialog-description"
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title">{t('user.confirmOnStatusUpdate')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t(`pointPurchase.askUpdateStatus.${statusTypeToCode[statusType]}`, { 'number': selectedRow ? selectedRow.length : 0 })}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUpdateDialog(false)} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={updateStatus} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        // flexDirection: 'row-reverse'
    },
}));
