import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { makeStyles, useTheme, Typography, Grid, Paper, Input, 
    InputAdornment, FormControl, Tooltip, Toolbar, LinearProgress, 
    IconButton, Icon, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, TableFooter,TextField,Button
} from '@material-ui/core';

import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import FilterListIcon from '@material-ui/icons/FilterList';
import RestoreIcon from '@material-ui/icons/Restore';

import * as XLSX from 'xlsx';

export default function ReportSalesFor2Month() {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const isMountedRef = useRef(null);

    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const maxSalesInfoRecords = 12;

    function callApi() {
        setTableLoading(true);
        getUrl('sales_user_listing', userFilter).then(result => {
            if (isMountedRef.current) {
                setTableLoading(false);
                setData(result.data);
            }
        }).catch((error) => {
            if (isMountedRef.current) {
                setTableLoading(false);
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            }
        });
    }

    useEffect(() => {
        isMountedRef.current = true;
        callApi();
        return () => { isMountedRef.current = false };
    }, []);

    const [userFilter, setUserIDFilter] = useState({});

    const [reset, setReset] = useState(false);

    // const exportFile = () => {
    //     let wb = XLSX.utils.table_to_book(document.getElementById('exportTable'));
    //     XLSX.writeFile(wb, `ReportSalesUsers_${Date.now()}.xlsx`);
    // }

    const exportFile = () => {
        // Prepare the data for export with "|" separator
        const exportData = data.map((item) => {
            const rowData = [
                item.username,
                Object.keys(item.mrank_date)
                    .map((mrankKey) => `${mrankKey}: ${item.mrank_date[mrankKey]}`)
                    .join(" |"),
                ...item.sales_info.map((salesItem) =>
                    `Date: ${salesItem.date} | Total Sales: ${salesItem["total Sales"]}`
                ),
            ];
            return rowData;
        });
    
        // Add header row
        const headerRow = ["Username", "Mrank Date", "Sales Info"];
        exportData.unshift(headerRow);
    
        // Create a new worksheet with the prepared data
        const ws = XLSX.utils.aoa_to_sheet(exportData);
    
        // Create a new workbook and add the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Report Sales Users");
    
        // Export the workbook with "|" as the separator
        XLSX.writeFile(wb, `ReportSalesUsers_${Date.now()}.xlsx`, {
            FS: "|", // Set "|" as the field separator
        });
    };   

    const handleUserID = (data) => {
        setUserIDFilter({ ...userFilter, data });
    }

    const handleReset = () => {
        setUserIDFilter({ ...userFilter, data: '' });
        setReset(true);
    }

    const handleFilter = () => {
        setTableLoading(true);
        callApi();
    }

    // Calculate the maximum number of sales items in any data row
    const maxSalesItems = data.reduce((max, item) => Math.max(max, item.sales_info.length), 0);

    useEffect(() => {
        if (reset) {
            callApi();
        }
    }, [reset]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h4" component="h1" className={classes.pageTitle}>
                    {t('reports.reportSalesUser.title')}
                </Typography>
            </Grid>
            <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                <Grid item>
                    <TextField
                        label={t('reports.accountLink.username')}
                        value={userFilter.data}
                        onChange={({ target }) => handleUserID(target.value)}
                        InputLabelProps={{ shrink: true }}
                        className={classes.filterInput}
                    />
                    <Tooltip title={t('table.exportExcel')}>
                        <IconButton type="button" onClick={exportFile} className={classes.iconButton} aria-label="export-excel">
                            <SaveAltIcon fontSize="default" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={handleReset}
                        startIcon={<RestoreIcon />}
                    >
                        {t('button.reset')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                        startIcon={<FilterListIcon />}
                        className={classes.filterButton}
                    >
                        {t('button.filter')}
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <TableContainer>
                        <Table className={classes.table} id="exportTable">
                            <TableHead>
                                {data.length !== 0 && (
                                    <TableRow>
                                        <TableCell className={classes.tableHeaderCell}>
                                            {t('reports.reportSalesUser.username')}
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderCell}>
                                            {t('reports.reportSalesUser.mrankDate')}
                                        </TableCell>
                                        <TableCell colSpan={maxSalesItems} className={classes.tableHeaderCell}>
                                            {t('reports.reportSalesUser.sales_info')}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableHead>
                            <TableBody>
                                {data.length !== 0 && _.map(data, (item, key) => (
                                    <TableRow key={key} className={key % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd}>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="subtitle1">{item.username}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div>
                                                {Object.keys(item.mrank_date).map((mrankKey, mrankIndex) => (
                                                    <div key={mrankIndex}>
                                                        <Typography className={classes.mrankItem}>
                                                            {mrankKey}: {item.mrank_date[mrankKey]}
                                                        </Typography>
                                                    </div>
                                                ))}
                                            </div>
                                        </TableCell>
                                        {Array(maxSalesItems).fill().map((_, index) => (
                                            <TableCell key={index} className={classes.tableCell}>
                                                {item.sales_info[index] ? (
                                                    <div className={classes.salesItem}>
                                                        <div className={classes.salesInfoGroup}>
                                                            <Typography className={classes.salesInfoTitle}>
                                                                {t('reports.reportSalesUser.date')}
                                                            </Typography>
                                                            <Typography className={classes.salesInfoValue}>
                                                                {item.sales_info[index].date}
                                                            </Typography>
                                                        </div>
                                                        <div className={classes.salesInfoGroup}>
                                                            <Typography className={classes.salesInfoTitle}>
                                                                {t('reports.reportSalesUser.totalSales')}
                                                            </Typography>
                                                            <Typography className={classes.salesInfoValue}>
                                                                {item.sales_info[index]['total Sales']}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                                {data.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={2 + maxSalesItems}>
                                            {tableLoading ? <LinearProgress className={classes.linearProgress} /> : t('table.noRecord')}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        border: `1px solid ${theme.palette.divider}`,
        borderCollapse: 'collapse',
    },
    tableRowEven: {
        backgroundColor: theme.palette.background.default,
        height: '100px',
    },
    tableRowOdd: {
        backgroundColor: theme.palette.action.hover,
        height: '100px',
    },
    tableHeaderCell: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        padding: theme.spacing(2),
        fontWeight: 'bold',
        border: `1px solid ${theme.palette.divider}`,
        textAlign: 'center',
    },
    tableCell: {
        padding: '30px',
        border: `1px solid ${theme.palette.divider}`,
        height: '150px',
        borderRadius: '0', // Set border-radius to create squared corners
    },
    salesInfoGroup: {
        display: 'flex',
        flexDirection: 'column',
    },
    salesInfoTitle: {
        fontWeight: 'bold',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    filterInput: {
        margin: theme.spacing(1),
    },
    filterButton: {
        marginLeft: theme.spacing(2),
    },
    linearProgress: {
        marginTop: theme.spacing(1),
    },
    mrankItem: {
        marginBottom: theme.spacing(1),
    },
    pageTitle: {
        fontSize: '24px',
        marginBottom: '16px',
    },
}));