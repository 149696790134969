import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import _ from 'lodash';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { Editor } from '@tinymce/tinymce-react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Breadcrumbs, Link, Paper, TextField, Button, Grid, FormControl, FormHelperText, Modal, MenuItem, Radio, RadioGroup, FormControlLabel, Checkbox, Slider } from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import DescriptionIcon from '@material-ui/icons/Description';

import { DropzoneArea } from 'material-ui-dropzone';
import theme from '../Theme';

// import ProductPointSection from './ProductPointSection';

// import DropdownTreeSelect from 'react-dropdown-tree-select';
// import 'react-dropdown-tree-select/dist/styles.css'
import CategoryPanel from './CategoryPanel';

function useInput({ type, label, val, placeholder, note='' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={6}>
            <TextField fullWidth size="small" label={label} error={error ? true : false} helperText={error} variant="outlined" value={value} onChange={e => setValue(e.target.value)} type={type} placeholder={placeholder}/>
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, input, setErrorValue];
}

function useCheckbox({ label, val, note='' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const checkbox =
        <Grid item xs={12} sm={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={e => setValue(e.target.checked)}
                        color="primary"
                    />
                }
                label={label}
            />
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, checkbox, setErrorValue];
}

// function useSelect({ data, label, val }) {
//     const classes = useStyles();
//     const selectedData = data[data.findIndex(item => item.id === val)]
//     const [value, setValue] = useState(selectedData);
//     const [error, setErrorValue] = useState("");
//     const select =
//         <Grid item xs={12} sm={6}>
//             <FormControl variant="outlined" fullWidth size="small" error={error ? true : false}>
//                 <Autocomplete
//                     onChange={(event, newValue) => { setValue(newValue); }}
//                     value={selectedData}
//                     // defaultValue={[]}
//                     size="small"
//                     options={data}
//                     classes={{ option: classes.option }}
//                     autoHighlight
//                     getOptionSelected={(option, value) => option.id === value.id}
//                     getOptionLabel={option => option.cat_name}
//                     renderOption={(option) => (
//                         <>{option.cat_name}</>
//                     )}
//                     renderInput={(params) => (
//                         <TextField
//                             {...params}
//                             label={label}
//                             fullWidth
//                             variant="outlined"
//                             inputProps={{
//                                 ...params.inputProps,
//                                 autoComplete: 'new-password', // disable autocomplete and autofill
//                             }}
//                             error={error ? true : false}
//                         />
//                     )}
//                 />
//                 {error ?
//                     <FormHelperText>{error}</FormHelperText>
//                     : null
//                 }
//             </FormControl>
//         </Grid>;
//     return [value, select, setErrorValue];
// }

function useCompanySelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {`${option.shop_name} [ID: ${option.id}]`}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useRefundSelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useWalletSelect({ data, label, val, note='' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => {
                    let name = _.split(option.name, '|');
                    return(
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    )
                })}
            </TextField>
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useEditor({ label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={12}>
            <FormControl variant="outlined" fullWidth size="small" error={error ? true : false}>
                <Typography variant="subtitle2" component="label">{label}</Typography>
                <Editor
                    apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                    value={value}
                    init={{
                        height: "70vh",
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount quickbars'
                        ],
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor forecolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                    }}
                    onEditorChange={(content, editor) => setValue(content)}
                />
                {error ?
                    <FormHelperText>{error}</FormHelperText>
                    : null
                }
            </FormControl>
        </Grid>;
    return [value, input, setErrorValue, error];
}

// function useGroupCheckbox({ data, label, val, note='' }) {
//     const [value, setValue] = useState(val);
//     const [error, setErrorValue] = useState("");
//     const handleChange = (event) => {
//         let tempData = value;
//         if(event.target.checked){
//             tempData = tempData.concat(event.target.value);
//         }else{
//             tempData = tempData.filter(item => parseInt(item) !== parseInt(event.target.value));
//         }
//         setValue(tempData);
//     }
//     const checkbox =
//         <Grid item xs={12} sm={6}>
//             <Typography>{ label }</Typography>
//             {data.map((detail) => {
//                 let checked = false;
//                 _.map(value, (rankId) => {
//                     if(parseInt(rankId) === parseInt(detail.id)){
//                         checked = true;
//                     }
//                 });
//                 if(parseInt(detail.id) === 0){
//                     detail.source = detail.name;
//                     detail.name_display = '';
//                 }
//                 return(
//                     <Grid item key={detail.id} style={{padding: 0}}>
//                         <FormControlLabel
//                             key={detail.id}
//                             control={<Checkbox value={detail.id} name={detail.name} checked={checked} onChange={(event) => handleChange(event)} />}
//                             label={detail.source + ' ' + detail.name_display}
//                         />
//                     </Grid>
//                 )
//             })}
//             <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
//         </Grid>;
//     return [value, checkbox, setErrorValue];
// }

// function useSlider({ label, val }) {
//     const [value, setValue] = useState(val);
//     const [error, setErrorValue] = useState("");
//     const handleChange = (e, value) => {
//         setValue(value);
//     }
//     const slider = 
//     <Grid item xs={12} sm={6}>
//         <Typography gutterBottom variant="body2" style={{color: "#808080"}}>
//             {label + ": " + value + "%"}
//         </Typography>
//         <Slider
//             aria-label={label}
//             defaultValue={val}
//             valueLabelDisplay="auto"
//             step={1}
//             marks
//             min={0}
//             max={16}
//             onChange={handleChange}
//             error={error ? true : false}
//             helperText={error}
//         />
//     </Grid>
//     return [value, slider, setErrorValue, setValue];
// }

export default function ProductAdd() {
    const classes = useStyles();

    const { t } = useTranslation();
    let history = useHistory();
    const { addAlert, setLoading } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const { companyId, role, permissions, isAdmin } = useSelector(state => state.general);

    // const [categories, setCategories] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [openModal, setOpenModal] = useState("");

    // const refundType = [{code: "normal", label: t('order.refundType.normal')}, {code: "special", label: t('order.refundType.special')}, {code: "no-refund", label: t('order.refundType.noRefund')}];
    // const interestType = [{code: "percent", label: t('order.interestType.percent')}, {code: "value", label: t('order.interestType.value')}];
    // const [wallets, setWallets] = useState([]);
    const [ranks, setRanks] = useState([]);
    const [mranks, setMRanks] = useState([]);
    // const [mistorePoint, setMistorePoint] = useState({
        //     cv: 0,
        //     mp: 0,
        // });
    // const productType = [{code: "normal", label: t('product.type.normal')}, {code: "rank", label: t('product.type.rank')}];
    const productType = isAdmin? [{code: "normal", label: t('product.type.normal')}, {code: "starter", label: t('product.type.starter')}, {code: "repurchase", label: t('product.type.repurchase')}] : [{code: "normal", label: t('product.type.normal')}, {code: "starter", label: t('product.type.starter')}];
    const amountType = [{code: "fix", label: t('product.aType.fix')}, {code: "percent", label: t('product.aType.percent')}];
    const [claimPoint, setClaimPoint] = useState({
        sp: 0,
        vp: 0
    });
    const [type, productTypeInput, setProductTypeError] = useRefundSelect({ data: productType, label: `${t('product.type.title')}*`, val: "normal" });
    const [title, titleInput, setNameError] = useInput({ type: "text", label: `${t('product.productName')}*`, val: "", placeholder:`${t('general.inputTips')}: ${t('product.english')}|${t('product.mandarin')}` });
    const [sku, skuInput, setSkuError] = useInput({ type: "text", label: `${t('product.sku')}`, val: "" });
    const [goods_brand, brandInput, setBrandError] = useInput({ type: "text", label: t('product.brand'), val: "", placeholder:`${t('general.inputTips')}: ${t('product.english')}|${t('product.mandarin')}` });
    // const [sell_price, sellingPriceInput, setSellingPriceError] = useInput({ type: "number", label: type == 'rank' ? t('product.priceStarter') : t('product.price'), val: "" });
    const [sell_price, sellingPriceInput, setSellingPriceError] = useInput({ type: "number", label: t('product.price'), val: "" });
    const [cost_price, costPriceInput, setCostPriceError] = useInput({ type: "number", label: t('product.costPrice'), val: "", note: 'Claimable Income: '+claimPoint.sp+' SP, '+claimPoint.vp+' VP' });
    // const [cost_price, costPriceInput, setCostPriceError] = useInput({ type: "number", label: t('product.costPrice'), val: "", note: "CV: "+mistorePoint.cv+" MP: "+mistorePoint.mp });
    const [retail_price, retailPriceInput, setRetailPriceError] = useInput({ type: "number", label: t('product.retailPrice'), val: "" });
    // const [category, categorySelect, setCategoryError] = useSelect({ data: categories, label: `${t('product.category')}*`, val: "0" });
    const [merchant_company_id, companySelect, setCompanyError] = useCompanySelect({ data: companies, label: `${t('product.shopName')}*`, val: "" });
    const [descr_en, descrEnInput, setDescrEnError, descrEnError] = useEditor({ label: t('product.descEn'), val: "" });
    const [descr_cn, descrCnInput, setDescrCnError, descrCnError] = useEditor({ label: t('product.descCn'), val: "" });
    const [quantity, quantityInput, setQuantityError] = useInput({ type: "number", label: `${t('product.stock')}*`, val: "" });
    const [shipping_fee, shippingFeeInput, setShippingFeeError] = useInput({ type: "number", label: t('product.shippingFee'), val: "" });
    const [west_shipping_fee, westShippingFeeInput, setWestShippingFeeError] = useInput({  type: "number", label: t('product.westShippingFee'), val: "" });
    const [east_shipping_fee, eastShippingFeeInput, setEastShippingFeeError] = useInput({  type: "number", label: t('product.eastShippingFee'), val: "" });
    const [ship_weight, weightInput, setWeightError] = useInput({ type: "number", label: `${t('product.weightKg')}*`, val: "" });
    const [package_length, lengthInput, setLengthError] = useInput({ type: "number", label: t('product.lengthCm'), val: '' });
    const [package_width, widthInput, setWidthError] = useInput({ type: "number", label: t('product.widthCm'), val: '' });
    const [package_height, heightInput, setHeightError] = useInput({ type: "number", label: t('product.heightCm'), val: '' });
    const [prepare_time, prepareTimeInput, setPrepareTimeError] = useInput({ type: "number", label: `${ t('product.prepareTime')}*`, val: 2, note: t('product.prepareTimeNote') });
    const [purchase_limit, purchaseLimitInput, setPurchaseLimitError] = useInput({ type: "number", label: `${ t('product.purchaseLimit')}*`, val: 0, note: t('product.purchaseLimitNote') });
    const [order_limit, orderLimitInput, setOrderLimitError] = useInput({ type: "number", label: `${ t('product.orderLimit')}*`, val: 0, note: t('product.orderLimitNote') });
    // const [bonus, bonusInput, setBonusError] = useCheckbox({ label: `${ t('product.bonus')}`, val: false, note: t('product.bonusNote') });
    // const [bv, bvInput, setBvError] = useInput({ type: "number", label: type == 'rank' ? t('product.bvStarter') : t('product.bv'), val: "" });
    const [bv, bvInput, setBvError] = useInput({ type: "number", label: t('product.bv'), val: "" });
    const [maxCap, maxCapInput, setMaxCapError] = useInput({ type: "number", label: t('product.maxCap'), val: "" });
    // const [auto_create_event, autoCreateEventInput, setAutoCreateEventError] = useCheckbox({ label: t('product.autoCreateEvent'), val: "", note: t('product.autoCreateEventNote') });
    // const [winner_quota, winnerQuotaInput, setWinnerQuotaError] = useInput({ type: "number", label: t('luckydraw.winnerQuota'), val: "" });
    // const [total_slot, totalSlotInput, setTotalSlotError] = useInput({ type: "number", label: t('luckydraw.totalSlot'), val: "", note: t('luckydraw.totalSlotNote') });
    // const [refund_type, refundTypeInput, setmrankIdInputRefundTypeError] = useRefundSelect({ data: refundType, label: t('order.refundType.title'), val: "" });
    // const [wallet_id, walletIdInput, setWalletIdError] = useWalletSelect({ data: wallets, label: t('wallet.walletType'), val: "" });
    // const [interest_type, interestTypeInput, setInterestTypeError] = useRefundSelect({ data: interestType, label: t('order.interestType.title'), val: "" });
    // const [interest_value, interestValueInput, setInterestValueError] = useInput({ type: "number", label: interest_type==='percent'?t('order.interestRate'):t('order.interestValue'), val: "" });
    const [rank_id, rankIdInput, setRankIdError] = useWalletSelect({ data: ranks, label: `${t('product.relatedRank')}*`, val: 0, note: t('product.relatedRankNote') });
    const [mrank_id, mrankIdInput, setMRankIdError] = useWalletSelect({ data: mranks, label: `${t('product.leadershipRank')}*`, val: 0, note: t('product.leadershipRankNote') });
    // const [allowed_rank_purchase, allowedRankPurchaseInput, setAllowedRankPurchaseError] = useGroupCheckbox({ data: ranks, label: t('product.allowedRankPurchase'), val: [], note: t('product.allowedRankPurchaseNote') });
    const [seller_cover_shipping, sellerCoverShippingInput, setSellerCoverShippingInputError] = useCheckbox({ label: `${ t('product.sellerCoverShipping')}`, val: false });
    // const [commission, commissionInput, setCommissionError] = useSlider({ label: t('product.commission'), val: 0});
    const [sell_price_repurchase, sellingPriceReInput, setSellingPriceReError] = useInput({ type: "number", label: t('product.priceRepurchase'), val: "" });
    const [bv_repurchase, bvReInput, setBvReError] = useInput({ type: "number", label: t('product.bvRepurchase'), val: "" });
    const [mp_reward_type, mpRewardTypeInput, setMpRewardTypeError] = useRefundSelect({ data: amountType, label: `VP ${t('product.aType.title')}`, val: 'fix' });
    const [mp_reward, mpRewardInput, setMpRewardError] = useInput({ type: "number", label: t('product.mpReward'), val: "" });

    const [newImages, setNewImages] = useState([]);
    const [newImagesError, setNewImagesError] = useState("");
    const [shipping_channel, setShippingChannel] = useState('self');
    const [categoryData, setCategoryData] = useState([]);
    // const [categoryTreeData, setCategoryTreeData] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [categoryError, setCategoryError] = useState("");
    // const [eventIdList, setEventIdList] = useState([]);
    const [malaysiaMerchant, setMalaysiaMerchant] = useState(false); // if true, can set shipping for east and west msia
    // const [pointList, setPointList] = useState([]);
    // const [pointData, setPointData] = useState([]);

    const validate = { title: setNameError, sku: setSkuError, goods_brand: setBrandError, sell_price: setSellingPriceError, cost_price: setCostPriceError,
        retail_price: setRetailPriceError, category_id: setCategoryError, descr_en: setDescrEnError, descr_cn: setDescrCnError, quantity: setQuantityError,
        images: setNewImagesError, merchant_company_id: setCompanyError, shipping_fee: setShippingFeeError, shipping_fee2: setEastShippingFeeError, ship_weight: setWeightError, package_length: setLengthError,
        package_width: setWidthError, package_height: setHeightError, prepare_time: setPrepareTimeError, purchase_limit: setPurchaseLimitError,order_limit: setOrderLimitError,
        bv: setBvError, rank_id: setRankIdError,mrank_id: setMRankIdError, mp_reward: setMpRewardError, mp_reward_type: setMpRewardTypeError,
        // auto_create_event: setAutoCreateEventError, winner_quota: setWinnerQuotaError, total_slot: setTotalSlotError, refund_type: setRefundTypeError, commission: setCommissionError, bonus: setBonusError,
        // wallet_id: setWalletIdError, interest_type: setInterestTypeError, interest_value: setInterestValueError,  allowed_rank_purchase: setAllowedRankPurchaseError,
        seller_cover_shipping: setSellerCoverShippingInputError, type: setProductTypeError, sell_price_repurchase: setSellingPriceReError, bv_repurchase: setBvReError, maxCap : setMaxCapError };

    useEffect(() => {
        isMountedRef.current = true;
        const checkId = isAdmin ? merchant_company_id : companyId;
        console.log("checkId", checkId);
        if(checkId) {
            getUrl(`admin/companies/${checkId}`).then(response => {
                if(response.status && isMountedRef.current) {
                    if(response.data.country === 'MY') {
                        setMalaysiaMerchant(true);
                    } else {
                        setMalaysiaMerchant(false);
                    }
                    console.log("response.data", response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => isMountedRef.current = false;
    }, [isAdmin, merchant_company_id, companyId]);

    function callApi(){
        let params = {'exclude_event':true };
        getUrl(`admin/categories`, params).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                // setCategories(result.data);
                setCategoryData(result.data)
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        })

        // if(role !== "super-merchant" && role !=="merchant"){
        if(isAdmin){
            getUrl(`admin/companies_by_roles`).then(result => {
                if(result.status === 1 && isMountedRef.current) {
                    setCompanies(result.data);
                }
            }).catch((error) => {
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            })
        }

        // getUrl(`admin/point_list`).then(result => {
        //     if(result.status === 1 && isMountedRef.current) {
        //         setPointList(result.data);
        //     }
        // }).catch((error) => {
        //     addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        // })

        // getUrl('luckydraw/id').then(response => {
        //     if(response.status === 1){
        //         setEventIdList(response.event_id_list);
        //     }
        // }).catch(error => {
        //     addAlert(JSON.stringify(error.message));
        // });

        // getUrl('admin/wallets').then(response => {
        //     if (isMountedRef.current && response.status === 1) {
        //         setWallets(response.data);
        //     }
        // }).catch(error => {
        //     addAlert(JSON.stringify(error.message));
        // });

        if(_.includes(permissions, "rank")){
            getUrl('admin/grouped_rank_list').then(response => {
                if (isMountedRef.current && response.status === 1) {
                    let tempRank = response.rank;
                    tempRank.push({id: 0, name: t('rank.noRank')});
                    setRanks(tempRank);
                    let tempMRank = response.mrank;
                    console.log("mrank",tempMRank)
                    tempMRank.push({id: 0, name: t('rank.noRank')})
                    setMRanks(tempMRank);
                    
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }else{
            let tempRank = [];
            tempRank.push({id: 0, name: t('rank.noRank')});
            setRanks(tempRank);
            setMRanks(tempRank);
        }

    }

    useEffect(() => {
        isMountedRef.current = true;
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);   

    useEffect(() => {
        isMountedRef.current = true;
        const checkId = isAdmin ? merchant_company_id : companyId;
        console.log("checkId", checkId);
        if(checkId) {
            getUrl(`admin/companies/${checkId}`).then(response => {
                if(response.status && isMountedRef.current) {
                    if(response.data.country === 'MY') {
                        setMalaysiaMerchant(true);
                    } else {
                        setMalaysiaMerchant(false);
                    }
                    console.log("response.data", response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => isMountedRef.current = false;
    }, [isAdmin, merchant_company_id, companyId]);

    // const categoryOnChange = (currentNode, selectedNodes) => {
    //     if(_.size(selectedNodes)>0){
    //         setCategoryId(currentNode.value);
    //         let temp = loopTreeData(categoryTreeData, currentNode.value);
    //         setCategoryTreeData(temp);
    //     }else{
    //         setCategoryId("");
    //         let temp = loopTreeData(categoryTreeData, "");
    //         setCategoryTreeData(temp);
    //     }
    // }
    // const loopTreeData = (treeData, parentId) => {
    //     let temp = treeData;
    //     _.map(treeData, (data, key) => {
    //         if(data.value === parentId){
    //             temp[key].checked = true;
    //         }else{
    //             temp[key].checked = false;
    //         }
    //         if(data.children){
    //             temp[key].children = loopTreeData(data.children, parentId);
    //         }
    //     })
    //     return temp;
    // }

    const changeCategory = currentId => {
        setCategoryId(currentId);
    }

    const categoryTreeSelect = () => {
        return (
        <Grid item xs={12} sm={12}>
            {/* <DropdownTreeSelect 
                data={categoryTreeData}
                mode="radioSelect"
                keepTreeOnSearch
                keepChildrenOnSearch
                texts={{ placeholder: t('table.search')+ ' ' +t('product.category') }}
                onChange={categoryOnChange}
            /> */}
            <CategoryPanel initialShow={true} initialData={categoryData} changeCategoryParent={changeCategory} />
            {
                categoryError
                ?
                <Typography variant="caption" style={{color: "red"}}>
                    {categoryError}
                </Typography>
                :null
            }
        </Grid>
        )
    }

    const resetAllFieldError = () => {
        _.map(validate, (valid) => {
            valid("");
        })
    };

    let handleSubmit = async () => {
        setLoading(true);
        resetAllFieldError();
        let newImagesBase64 = [];

        if(_.size(newImages) > 0){
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

            for (const newImg of newImages) {
                newImagesBase64.push(await toBase64(newImg));
            }
        }

        let apiData = {
            title,
            category_id: categoryId,
            // category_id: category ? category.id : "",
            merchant_company_id,
            goods_brand,
            sell_price,
            cost_price,
            retail_price,
            quantity,
            descr_en,
            descr_cn,
            images: newImagesBase64,
            shipping_channel,
            shipping_fee: malaysiaMerchant ? west_shipping_fee : shipping_fee,
            shipping_fee2: malaysiaMerchant ? east_shipping_fee : 0,
            ship_weight,
            package_length,
            package_width,
            package_height,
            prepare_time,
            purchase_limit,
            sku,
            // point_data: pointData,
            // bonus: bonus ? 1 : 0,
            bv,
            // auto_create_event: auto_create_event ? 1 : 0,
            // winner_quota,
            // total_slot,
            // refund_type,
            // wallet_id,
            // interest_type,
            // interest_value,
            rank_id: type == 'normal' ? null : rank_id,
            mrank_id: type == 'normal' ? null : mrank_id,
            maxCap : type == 'normal' ? null : maxCap,
            // allowed_rank_purchase,
            seller_cover_shipping: seller_cover_shipping ? 1 : 0,
            // commission,
            type,
            sell_price_repurchase,
            bv_repurchase,
            mp_reward_type,
            mp_reward,
            order_limit
        }

        postUrl(`admin/products`, apiData)
        .then((response) => {
            if(isMountedRef.current) {
                setLoading(false);
                let {status, message, error, data} = response;
                if(status === 1){
                    addAlert('', message, 'success', '');
                    history.replace(`/products/${data.id}`);
                }else{                    
                    if(_.size(error) > 0){
                        _.map(error, (value, key) => {
                            if(validate[key])
                                validate[key](value[0]);
                            else
                                message += "\n "+value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    useEffect(() => {
        // calculate cost price, commission display
        let sp = 0;
        let vp = 0;
        if(sell_price > 0){
            // let claim = parseFloat(sell_price*0.2).toFixed(2);
            let claim = parseFloat(sell_price).toFixed(2);
            if(type === 'starter'){
                // vp = parseFloat(claim*0.2).toFixed(2);
                // sp = parseFloat(claim - vp).toFixed(2);
                sp = parseFloat(claim*0.2).toFixed(2);
                vp = parseFloat(claim*0).toFixed(2);
            }else{
                sp = parseFloat(cost_price)>0 ? parseFloat(cost_price).toFixed(2): 0;
            }
        }
        setClaimPoint({ ...claimPoint, sp: sp, vp: vp });
    }, [sell_price, cost_price, type]);

    return(
        <Grid container spacing={3}>            
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('product.addProduct')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/products`} component={RouterLink}>{t('product.products')}</Link>
                        <Typography style={{ color: 'black' }}>{t('product.addProduct')}</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                    <Grid container spacing={3} style={theme.p20}>
                        <Grid item xs={12}>
                            <Typography variant="h5" component="h1">{t('product.aboutProduct')}</Typography>
                            {/* <Divider /> */}                            
                        </Grid>
                        <Grid item xs={12} container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.generalInfo')}</Typography>
                            </Grid>
                            {titleInput}
                            {/* {_.size(ranks) > 1 ? productTypeInput : null} */}
                            {productTypeInput}
                            {_.size(companies) ? companySelect : null}
                            {skuInput}
                            {brandInput}
                            {/* {categorySelect} */}
                            {categoryTreeSelect()}
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.priceAndBv')}</Typography>
                            </Grid>
                            {sellingPriceInput}
                            {/* {type == 'rank' ? sellingPriceReInput : null} */}
                            {costPriceInput}
                            {retailPriceInput}
                            {_.includes(permissions, "product_bonus") ? bvInput : null}
                            {/* {_.includes(permissions, "product_bonus") ? (type == 'rank' ? bvReInput : null) : null} */}
                            {_.includes(permissions, "product_bonus") ? mpRewardTypeInput : null}
                            {_.includes(permissions, "product_bonus") ? mpRewardInput : null}
                            {_.size(ranks) > 1 ? (type == 'normal' ? null : rankIdInput) : null}
                            {_.size(mranks) > 1 ? (type == 'normal' ? null : mrankIdInput) : null}
                            {type == 'normal' ? null : maxCapInput}
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.stock')}</Typography>
                            </Grid>
                            {quantityInput}
                            {purchaseLimitInput}
                            {orderLimitInput}
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.weightAndDimensional')}</Typography>
                            </Grid>
                            {weightInput}
                            {lengthInput}
                            {widthInput}
                            {heightInput}
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.others')}</Typography>
                            </Grid>
                            {prepareTimeInput}
                            {/* {commissionInput} */}
                            {/* {_.includes(permissions, "product_bonus") ? bonusInput : null}   */}
                            {/* {(_.includes(permissions, "event") && eventIdList.includes(String(categoryId))) ? 
                                <>
                                    {autoCreateEventInput}
                                    {winnerQuotaInput}
                                    {totalSlotInput}
                                    {refundTypeInput}
                                    {
                                        refund_type === 'special'
                                        ?
                                        <>
                                        {walletIdInput}
                                        {interestTypeInput}
                                        {interestValueInput}
                                        </>
                                        : null
                                    }
                                </>
                                : null
                            }
                            {isAdmin ? allowedRankPurchaseInput : null} */}
                        </Grid>
                        <Grid item xs={12} style={theme.mt30}>
                            <Typography variant="h5" component="h1">{t('product.productDescription')}</Typography>
                            {/* <Divider /> */}
                        </Grid>
                        <Grid item xs={12} container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} onClick={() => setOpenModal("descr_en")}>{t('product.english')}</Button>
                                {descrEnError ?
                                    <FormHelperText style={{ color: "red" }}>{descrEnError}</FormHelperText>
                                    : null
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} onClick={() => setOpenModal("descr_cn")}>{t('product.mandarin')}</Button>
                                {descrCnError ?
                                    <FormHelperText style={{ color: "red" }}>{descrCnError}</FormHelperText>
                                    : null
                                }
                            </Grid>
                        </Grid>
                        <Modal
                            open={openModal ? true : false}
                            onClose={() => setOpenModal("")}
                            aria-labelledby="product description"
                            aria-describedby="product description modal"
                        >
                            <Grid container spacing={3} justify="center" className={classes.modal}>
                                {openModal === "descr_cn" ? descrCnInput : descrEnInput}
                                <Grid item xs={12} sm={6}>
                                    <Button type="button" fullWidth variant="contained" color="primary" onClick={() => setOpenModal("")}>{t('button.close')}</Button>
                                </Grid>
                            </Grid>
                        </Modal>
                        <Grid item xs={12} style={theme.mt30}>
                            <Typography variant="h5" component="h1">{t('product.shippingChannel')}</Typography>
                            {/* <Divider /> */}
                        </Grid>
                        <Grid item xs={12} container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <FormControl component="fieldset">
                                    {/* <FormLabel component="legend">{t('product.shippingChannel')}</FormLabel> */}
                                    <RadioGroup aria-label="shipping channel" name="shippingChannel" value={shipping_channel} onChange={(event) => { setShippingChannel(event.target.value) }}>
                                        <FormControlLabel value="self" control={<Radio />} label={t('product.selfShipping')} />
                                        <FormControlLabel value="mall" control={<Radio />} label={t('product.mallShipping')} />
                                    </RadioGroup>
                                    <FormHelperText style={{paddingBottom: 5}}>{t('product.shippingWeightNote')}</FormHelperText>
                                </FormControl>
                                { shipping_channel === "self" ? 
                                    <>
                                        { malaysiaMerchant ? 
                                            <>
                                                { westShippingFeeInput }
                                                <div style={{ marginTop: 10 }}>
                                                { eastShippingFeeInput }
                                                </div>
                                            </>
                                        : shippingFeeInput }
                                    </>
                                : null }
                                {shipping_channel === "mall" ? sellerCoverShippingInput : null}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={theme.mt30}>
                            <Typography variant="h5" component="h1">{t('product.uploadImages')}</Typography>
                            {/* <Divider /> */}
                        </Grid>
                        <Grid item xs={12} container className={classes.imageRoot}>
                            <DropzoneArea
                                acceptedFiles={['image/*']}
                                dropzoneText={t('product.dragDropImageHere')}
                                onChange={(files) => setNewImages(files)}
                                filesLimit={10}
                                dropzoneClass={classes.dropzone}
                                maxFileSize={2000000}
                            />
                            <Typography variant="body2" style={{margin: 5}}>{t('general.maxFile', {max: 10}) + t('general.productfileSizeLimit', {limit: '2MB', fileType: 'jpeg. jpg. png. '})}</Typography>
                            {newImagesError ?
                                <FormHelperText style={{ color: "red" }}>{newImagesError}</FormHelperText>
                                : null
                            }
                        </Grid>
                        {/* {
                            _.size(pointList) > 0
                            ?
                            <ProductPointSection pointList={pointList} pointData={pointData} setPointData={setPointData}/>
                            :
                            null
                        } */}
                        <Grid container spacing={3} justify="center">
                            <Grid item xs={8} sm={6} md={4} style={{marginTop: 30}}>
                                <Button type="button" fullWidth variant="contained" color="primary" onClick={handleSubmit}>{t('button.proceed')}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },    
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    imageRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '20px',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        overflow: 'auto'
    },
    dropzone: {
        outline: "none",
    }
}));
