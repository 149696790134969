import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '../helper/Tools';
import _ from 'lodash';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Breadcrumbs, Link, Grid, Paper, Button, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

export default function PointPurchaseAdd() {
    const classes = useStyles();
    const theme = useTheme();
    const query = useQuery();

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState([]);
    const [source, setSource] = useState([]);
    const [state, setState] = useState({
        name: '',
        username: '',
        price: '',
        source: '',
        status: '1',
    });

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`admin/pointPurchase/add`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                setSource(response.data);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        const updateData = {
            name: state.name,
            username: state.username,
            point: state.point,
            status: state.status,
            source: state.source,
        }
        console.log(updateData);
        postUrl(`admin/pointPurchase/add`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                if(response.data){
                    let msg = t('snackbarMsg.updateSuccess')+' ID: ' + response.data.id;
                    addAlert('', msg, 'success', '');
                }else{
                    let msg = t('snackbarMsg.updateSuccess');
                    addAlert('', msg, 'success', '');
                }
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    };

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('title.pointPurchaseAdd')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/point-purchase`} component={RouterLink}>{t('title.pointPurchase')}</Link>
                        <Typography style={{ color: 'black' }}>{t('title.pointPurchaseAdd')}</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                <Grid container spacing={3} direction="row" style={theme.p20}>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="name" 
                            label={t('profile.name')} 
                            variant="outlined" 
                            fullWidth 
                            value={state.name}
                            helperText={inputErrors.name?inputErrors.name:''}
                            error={inputErrors.name?true:false}
                            onChange={({ target }) => setState({ ...state, name: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="username" 
                            label={t('profile.username')} 
                            variant="outlined" 
                            fullWidth 
                            value={state.username}
                            helperText={inputErrors.username?inputErrors.username:''}
                            error={inputErrors.username?true:false}
                            onChange={({ target }) => setState({ ...state, username: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="source" 
                            label={t('point.source')} 
                            variant="outlined" 
                            fullWidth 
                            select
                            value={state.source}
                            helperText={inputErrors.source?inputErrors.source:''}
                            error={inputErrors.source?true:false}
                            onChange={({ target }) => setState({ ...state, source: target.value }) }
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                        >
                        <option key='' value='' disabled>
                            {t('general.pleaseSelect')}
                        </option>
                        {source.map((value) => {
                            return (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            )
                        })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="point" 
                            label={t('point.points')}
                            variant="outlined" 
                            fullWidth 
                            value={state.point}
                            helperText={inputErrors.point?inputErrors.point:''}
                            error={inputErrors.point?true:false}
                            onChange={({ target }) => setState({ ...state, point: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="status" 
                            label={t('table.status')}
                            variant="outlined" 
                            fullWidth 
                            select
                            value={state.status}
                            helperText={inputErrors.status?inputErrors.status:''}
                            error={inputErrors.status?true:false}
                            onChange={({ target }) => setState({ ...state, status: target.value }) }
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                        >
                            <option key='0' value='0'>Inactive</option>
                            <option key='1' value='1'>Active</option>
                            <option key='2' value='2'>Cancelled</option>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button variant="contained" color="primary" onClick={form_submit} >{t('button.submit')}</Button>
                    </Grid>
                </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));